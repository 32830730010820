.navbar {
  // border: 2px solid red;
  padding: 15px 20px;
  box-shadow: 0 2px 15px 5px rgb(225, 225, 223);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  z-index: 999;
  background: white;

  @media (max-width: $breakpoint-tablet) {
    padding: 20px;
  }

  & .logo {
    & img {
      width: 230px;
      object-fit: contain;
    }
  }

  & .nav-center {
    // border: 2px solid blue;
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s linear;

    & .nav-links {
      display: flex;
      gap: 67px;
      transition: all 0.2s linear;

      @media (max-width: $breakpoint-tablet) {
        // display: block;
        position: absolute;
        padding: 0;
        top: 100%;
        left: 0;
        width: 100%;
        background: white;
        flex-direction: column;
        height: 0;
        transition: all 0.2s linear;
        box-shadow: var(--box-shadow);
      }

      & .link-item {
        text-transform: capitalize;
        color: #046604;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        cursor: pointer;
        text-decoration: none;
        transition: var(--transition);

        @media (max-width: $breakpoint-tablet) {
          display: none;
          height: 0;
        }

        &:hover {
          opacity: 0.8;
          transition: var(--transition);
        }
      }

      & .active {
        color: #fc6702;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    @media (max-width: $breakpoint-tablet) {
      & .open {
        height: auto;
        transition: all 0.2s linear;
        transform-origin: top;
        padding: 20px;

        & .link-item {
          display: flex;
          height: auto;
        }
      }
    }
  }

  & .hamburger {
    display: none;
    background: transparent;
    border: none;
    cursor: pointer;

    & img {
      height: 30px;
      width: 30px;
    }

    @media (max-width: $breakpoint-tablet) {
      display: block;
    }
  }
}
