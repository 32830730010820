@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
*,
body,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

:root {
  --max-width: 1235px;
  --primary-orange: #fc6702; /* rgb(252, 103, 2) */
  --primary-green: #006600; /* rgb(0, 102, 0) */
  --primary-grey: rgb(183, 183, 183); /* rgb(0, 102, 0) */
  --secondary-grey: #666666;

  --light-green: #0466040d;
  --light-green-200: rgba(154, 203, 52, 0.5);
  --light-orange: rgba(252, 103, 2, 0.25);

  --transition: all linear 0.2s;
  --box-shadow: 0 5px 10px 7px rgba(136, 140, 133, 0.05);
}
