.contact {

  & .section-two {
    padding: 90px 30px 90px;
    background: var(--light-green);

    @media (max-width: $breakpoint-tablet) {
      padding: 50px 15px;
    }

    & .section-center {
      max-width: var(--max-width);
      margin: 0 auto;
      //   border: 2px solid blue;
      display: flex;
      gap: 40px;
      justify-content: space-between;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }

      & .left {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 40px 0;

        & h2 {
          color: var(--primary-orange);
          font-size: 13px;
          text-transform: uppercase;
        }

        & h1 {
          font-size: 50px;
          color: var(--primary-green);
          // color: #9c6a37;
          text-transform: capitalize;

          @media (max-width: $breakpoint-tablet) {
            font-size: 40px;
          }
        }

        & h3 {
          color: var(--secondary-grey);
          // color: rgb(132, 109, 83);
          font-size: 19px;

          @media (max-width: $breakpoint-tablet) {
            font-size: 17px;
          }
        }

        & .items {
          display: flex;
          flex-direction: column;
          gap: 40px;

          & .item {
            display: flex;
            gap: 20px;

            & span {
              font-size: 45px;
              color: white;
              padding: 15px;
              border-radius: 50%;
              background: #557d3e;
              height: fit-content;
              width: fit-content;
              display: flex;
              justify-content: center;
              align-items: center;

              @media (max-width: $breakpoint-tablet) {
                font-size: 35px;
              }
            }

            & .text {
              display: flex;
              flex-direction: column;
              gap: 15px;

              & h2 {
                font-size: 28px;
                // color: var(--primary-orange);
                color: black;
                // color: #9c6a37;

                @media (max-width: $breakpoint-tablet) {
                  font-size: 25px;
                }
              }

              & p {
                font-size: 17px;
                color: rgb(168, 152, 137);
                max-width: 235px;

                @media (max-width: $breakpoint-tablet) {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }

      & .right {
        // border: 2px solid red;
        background: white;
        padding: 70px;
        max-width: 530px;
        display: flex;
        align-items: center;
        min-width: 50%;
        justify-content: center;

        @media (max-width: $breakpoint-tablet) {
          padding: 30px;
          min-width: 100%;
          max-width: 100%;
        }

        & form {
          display: flex;
          flex-direction: column;
          gap: 30px;
          justify-content: center;
          width: 100%;

          & input,
          textarea {
            // width: 380px;
            width: 100%;
            padding: 12px 21px;
            font-size: 15px;
            border: none;
            // background-color: #f8f4f1;
            background-color: var(--light-green);
            border-radius: 10px;
            --w-get-input-background-color: #f8f4f1;

            &::placeholder {
              color: #b3a9a2;
            }
          }

          & textarea {
            height: 180px;
            resize: none;
          }

          & button {
            padding: 12px 21px;
            border: none;
            // color: #9c6a37;
            color: white;
            background: var(--primary-orange);
          // background: #d7c2aa;
            border-radius: 7px;
            cursor: pointer;
            border: 1px solid var(--primary-orange);
            transition: var(--transition);

            &:hover{
              transition: var(--transition);
              background: white;
              color: var(--primary-orange);
              border: 1px solid var(--primary-orange);
            }
          }
        }
      }
    }
  }

  & .map {
    height: 90vh;
  }
}
