.footer {
  // border: 2px solid red;
  // background: var(--light-green);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
  position: relative;

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #8eda7f26;
  }

  @media (max-width: $breakpoint-tablet) {
    padding-top: 130px;
  }

  & .footer-center {
    margin: 0 auto;
    padding: 55px 0;
    display: flex;
    max-width: var(--max-width);
    margin: 0 auto;
    // border: 2px solid blue;
    justify-content: space-between;
    gap: 60px;
    position: relative;
    z-index: 1;

    @media (max-width: $breakpoint-large) {
      flex-wrap: wrap;
    }
    @media (max-width: $breakpoint-tablet) {
      padding: 20px;
      gap: 30px;
    }

    & .left {
      display: flex;
      flex-direction: column;
      //   border: 2px solid salmon;
      gap: 25px;

      & .logo {
        // width: fit-content;
        & img {
          width: 150px;
        }
      }

      & p {
        max-width: 300px;
        color: #000;
        text-align: justify;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
      }

      & .socials {
        display: flex;
        gap: 25px;

        & a {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          cursor: pointer;
          color: white;
          border-radius: 5px;
          background: var(--primary-green);
          padding: 5px;
          cursor: pointer;
        }
      }
    }

    & .middle,
    .right {
      display: flex;
      flex-direction: column;
      gap: 25px;

      & h2 {
        color: #000;
        text-align: justify;
        font-size: 24px;
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: capitalize;
      }

      & .link-item {
        color: #000;
        text-align: justify;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    & .right {
      & p {
        color: #000;
        text-align: justify;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 300;
        max-width: 340px;
        line-height: 24px;
      }
      & .info {
        max-width: 340px;
      }

      & input {
        padding: 10px 20px;
        border-radius: 5px;
        // background: #046604;
        // background: var(--light-green);
        background: white;
        font-size: 16px;
        border: none;
        // color: white;
        color: var(--primary-green);

        &::placeholder {
          color: var(--light-green);
          // color: rgba(255, 255, 255, 0.46);
          text-align: justify;
          font-size: 16px;
          font-family: Inter;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }

      & button {
        padding: 10px 20px;
        border-radius: 5px;
        background: var(--primary-orange);
        border: 1px solid var(--primary-orange);
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        cursor: pointer;
        transition: var(--transition);

        &:hover {
          transition: var(--transition);
          background: white;
          color: var(--primary-orange);
          border: 1px solid var(--primary-orange);
        }
      }
    }
  }
}
