.news {
  & h2 {
    color: var(--primary-orange);
    font-size: 30px;
    // text-transform: uppercase;
    margin-bottom: 40px;
    margin: 0 auto 40px;
    text-align: center;
    padding-top: 50px;
  }

  & .content {
    display: flex;
    width: 80%;
    margin: 0 auto;
    // gap: 30px;
    height: fit-content;
    // border: 2px solid red;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
  }

  & .section-two,
  .section-three {
    width: 70%;
    margin: 0 auto;
    max-width: 900px;
    gap: 30px;
    // padding-top: 50px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 2px solid blue;

    & .twitter-timeline {
      width: unset;
    }

    // & ._2p3a {
    //   width: unset;
    // }
  }

  // & .line {
  //   height: 500px;
  //   width: 1px;
  //   background: rgb(235, 233, 233);

  //   @media (max-width: $breakpoint-tablet) {
  //     display: none;
  //   }
  // }
}
