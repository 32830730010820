@import "../styles/modules/variables";
@import "../styles/modules/navbar";
@import "../styles/modules/landing";
@import "../styles/modules/about";
@import "../styles/modules/contact";
@import "../styles/modules/services";
@import "../styles/modules/news";
@import "../styles/modules/footer";

.main{
    margin-top: 110px;
}

.section-one {
      // border: 2px solid red;
    min-height: 319px;
    // background: var(--primary-green);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    background-color: rgba(0, 102, 0,0.4);
    }

    & .section-center {
      position: relative;
      z-index: 2;      
      max-width: var(--max-width);
      // border: 2px solid blue;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;

      & h2 {
        font-size: 42px;
        color: white;
        text-transform: capitalize;
      }

      & .link {
        display: flex;
        gap: 5px;

        & a,
        p,
        span {
          font-size: 13px;
          text-transform: uppercase;
          text-decoration: none;
          color: white;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  iframe{
    -moz-border-radius:10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    // margin: 0 auto;
  }