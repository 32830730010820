.landing {
  // padding: 0px 0 100px;

  @media (max-width: $breakpoint-large) {
    padding: 80px 0;
  }

  @media (max-width: $breakpoint-tablet) {
    padding: 60px 0;
  }

  & .banner {
    // border: 2px solid red;
    margin: 0 auto 80px;
    height: 100vh;
    max-height: 700px;
    position: relative;
    // background-position: ;
    background-size: cover;
    display: flex;
    // justify-content: center;
    align-items: center;

    &::before {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      background: rgba(73, 69, 69, 0.5);
    }

    &_center {
      max-width: var(--max-width);
      margin: 0 auto;
      z-index: 1;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: white;

      & h1 {
        max-width: 65%;
        font-size: 45px;
        margin-bottom: 30px;
        font-weight: 600;
        letter-spacing: 1px;
      }

      & p {
        font-size: 20px;
        max-width: 65%;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0.5px;
      }
    }
  }

  & .history {
    // border: 2px solid red;
    max-width: var(--max-width);
    // max-width: 1080px;
    margin: 0px auto 130px;
    display: flex;
    flex-direction: column;
    gap: 60px;

    & h1 {
      font-size: 45px;
      font-weight: 600;
      text-align: center;

      & span {
        color: var(--primary-orange);
      }
    }

    & .hero {
      display: flex;
      gap: 40px;

      &_image {
        // width: 50%;

        & img {
          // width: 400px;
          width: 100%;
          height: 400px;
          object-fit: cover;
          border-radius: 8px;
        }
      }

      &_text {
        display: flex;
        flex-direction: column;
        // border: 2px solid blue;
        gap: 20px;

        & p {
          // max-width: 950px;
          font-size: 18px;
          letter-spacing: 0.5px;
          line-height: 32px;
          font-weight: 300;
          border-radius: 8px;
          color: rgb(68, 63, 63);
          max-width: 700px;
        }
      }
    }
  }

  &_intro {
    // border: 2px solid red;
    max-width: var(--max-width);
    // max-width: 1080px;
    margin: 90px auto 130px;
    display: flex;
    flex-direction: column;

    & h1 {
      font-size: 45px;
      font-weight: 600;
      text-align: center;

      & span {
        color: var(--primary-orange);
      }
    }

    & h2 {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.5px;
      font-weight: 300;
      // max-width: 900px;
      margin: 70px auto 40px;
      // text-align: center;
      max-width: 1080px;
    }

    & .innovatives {
      display: flex;
      flex-direction: column;
      gap: 30px;

      & .innovative {
        // border: 2px solid blue;
        max-width: 1080px;
        margin: 0 auto;
        display: flex;
        gap: 50px;
        align-items: center;

        & img {
          height: 120px;
          width: 120px;
        }

        & p {
          max-width: 950px;
          font-size: 18px;
          letter-spacing: 0.5px;
          line-height: 28px;
          font-weight: 300;
          padding: 30px 20px;
          border-radius: 8px;
          color: rgb(68, 63, 63);
        }

        & .grey {
          background: var(--primary-grey);
        }
      }
    }
  }

  &_about {
    // border: 2px solid blue;
    background: var(--light-green);
    display: flex;
    flex-direction: column;
    padding: 90px 0 140px;

    & .learn-more {
      margin-top: 50px;
      width: fit-content;
      padding: 16px 32px;
      color: white;
      background-color: var(--primary-orange);
      margin: 50px auto;
      font-size: 18px;
      text-decoration: none;
      border-radius: 6px;
      cursor: pointer;
      transition: var(--transition);

      &:hover {
        color: var(--primary-orange);
        background: white;
        border: 1px solid var(--primary-orange);
        transition: var(--transition);
      }
    }

    & h1 {
      font-size: 45px;
      font-weight: 600;
      text-align: center;
      max-width: var(--max-width);
      margin: 0 auto;

      & span {
        color: var(--primary-orange);
      }
    }

    & .sub-heading {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: 0.5px;
      margin: 40px auto 80px;
      // text-transform: uppercase;
      text-align: center;
      color: rgb(68, 63, 63);
      max-width: 950px;
      line-height: 28px;
      max-width: var(--max-width);
    }

    & h3 {
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.5px;
      margin: 0 auto;
      margin-bottom: 20px;
      line-height: 28px;
      color: rgb(68, 63, 63);
      max-width: 1100px;
      text-align: justify;
      max-width: var(--max-width);
    }
  }

  & .landing-center {
    max-width: var(--max-width);
    margin: 30px auto 0;
    display: flex;
    gap: 20px;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      gap: 10px;
    }

    & .landing-card {
      & .image {
        height: auto;
        max-width: 610px;
        max-height: 405px;
        position: relative;

        @media (max-width: $breakpoint-tablet) {
          max-width: 100%;
        }

        & .img-overlay {
          opacity: 0;
          position: absolute;
          // background: rgba(252, 103, 2, 0.50);
          top: 0;
          left: 0;
          z-index: 3;
          // height: 100%;
          height: 413px;
          width: 100%;
          display: flex;
          align-items: center;
          padding-left: 67px;
          // justify-content: center;
          transition: var(--transition);

          @media (max-width: $breakpoint-tablet) {
            height: 100%;
          }

          & img {
            transform: translateX(40px);
            transition: var(--transition);
          }
        }

        & .main-img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      & .overlay {
        & .img-overlay {
          opacity: 1;
          transition: var(--transition);

          & img {
            transform: translateX(0);
            transition: var(--transition);
          }
        }
      }

      & .info-container {
        padding: 35px;

        @media (max-width: $breakpoint-tablet) {
          padding: 20px;
        }
      }
      & .info {
        // border: 2px solid red;
        max-width: 478px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (max-width: $breakpoint-tablet) {
          max-width: 100%;
          gap: 5px;
        }

        & h2 {
          color: #fff;
          font-size: 30px;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: -0.6px;

          @media (max-width: $breakpoint-tablet) {
            font-size: 24px;
            line-height: 32px;
          }
        }

        & p {
          color: #fff;
          text-align: justify;
          font-size: 16px;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          @media (max-width: $breakpoint-tablet) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        & button,
        a {
          text-decoration: none;
          border-radius: 5px;
          background: #fff;
          box-shadow: 0px 8px 30px 0px rgba(0, 191, 178, 0.1);
          width: fit-content;
          padding: 10px 20px;
          border: none;
          //   color: #fc6702;
          color: black;
          text-align: center;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          text-transform: capitalize;
          cursor: pointer;
          transition: var(--transition);

          @media (max-width: $breakpoint-tablet) {
            padding: 8px 15px;
            font-size: 12px;
          }

          &:hover {
            opacity: 0.9;
            transition: var(--transition);
          }
        }

        & a {
          // background: black;
        }
      }
    }
  }
}
