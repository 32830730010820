.services {
  & .section-two {
    // border: 2px solid red;
    padding: 80px 0;

    @media (max-width: $breakpoint-mobile) {
      padding: 40px 0;
    }

    & .section-center {
      width: 80%;
      max-width: var(--max-width);
      // border: 2px solid blue;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 50px;
      align-items: center;
      justify-content: center;

      @media (max-width: $breakpoint-tablet) {
        width: 90%;
      }

      & h3 {
        color: var(--primary-orange);
        font-size: 13px;
        text-transform: uppercase;
      }

      & h2 {
        font-weight: 400;
        font-size: 23px;
        // text-align: center;
        color: var(--secondary-grey);
        // color: red;

        @media (max-width: $breakpoint-mobile) {
          font-size: 20px;
          text-align: center;
        }
      }

      & .learn-more {
        padding: 18px 30px;
        background: #9acb34;
        color: white;
        text-decoration: none;
        font-size: 22px;
        border-radius: 8px;
        cursor: pointer;

        transition: var(--transition);

        &:hover {
          color: var(--primary-green);
          background: white;
          border: 1px solid var(--primary-green);
          transition: var(--transition);
        }
      }

      & .hawaree-btn {
        background-color: var(--primary-orange);

        &:hover {
          color: var(--primary-orange);
          border: 1px solid var(--primary-orange);
        }
      }

      & .webzen {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        & h1 {
          font-size: 50px;
          // color: var(--secondary-grey);
          // color: var(--primary-green);
          color: #9acb34;
          text-transform: capitalize;
          text-align: center;

          @media (max-width: $breakpoint-mobile) {
            font-size: 40px;
            text-align: center;
          }
        }

        & h2 {
          text-align: center;
          max-width: 900px;
        }

        & p {
          font-size: 19px;
          color: var(--secondary-grey);
          width: 100%;
          text-align: center;
          margin: 0 auto;
          max-width: 280px;

          @media (max-width: $breakpoint-tablet) {
            width: 85%;
            font-size: 17px;
          }
        }

        & .zen-items {
          display: flex;
          gap: 30px;
          margin-top: 40px;

          @media (max-width: $breakpoint-tablet) {
            flex-direction: column;
            gap: 20px;
          }

          & .zen-item {
            display: flex;
            flex-direction: column;
            gap: 20px;

            & img {
              height: 192px;
              width: 289px;
              object-fit: cover;
              border-radius: 15px;
            }

            & h4 {
              font-size: 20px;
              font-weight: 600;
              text-align: center;
              height: 40px;
              letter-spacing: 1px;

              @media (max-width: $breakpoint-mobile) {
                font-size: 18px;
              }
            }

            & p {
              font-size: 15px;
              text-align: justify;
              line-height: 25px;

              @media (max-width: $breakpoint-mobile) {
                font-size: 14px;
              }
            }
          }
        }
      }

      & .hawaree {
        & h1 {
          color: var(--primary-orange);
        }
      }
    }
  }
}
