.about {
  & .section-two {
    background: var(--light-green);
    padding: 120px 0px 0px;
    // max-width: var(--max-width);
    // margin: 0 auto;

    & .content {
      padding-bottom: 120px;
      // border: 2px solid blue;
      margin: 0 auto;
      max-width: var(--max-width);
      display: flex;
      gap: 50px;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        gap: 20px;
      }

      & .left {
        max-width: 530px;
        max-height: 435px;

        & img {
          height: 100%;
          width: 100%;
          border-radius: 16px;
        }
      }

      & .right {
        display: flex;
        flex-direction: column;
        gap: 30px;

        & h2 {
          color: var(--primary-orange);
          font-size: 13px;
          text-transform: uppercase;
        }

        & h1 {
          font-size: 50px;
          // color: var(--secondary-grey);
          color: var(--primary-green);
          text-transform: capitalize;
          letter-spacing: 1.5px;
        }

        & h3 {
          // color: rgb(132, 109, 83);
          color: var(--secondary-grey);
          font-size: 25px;
          width: 500px;
          line-height: 35px;
          letter-spacing: 0.5px;
          font-weight: 300;
        }

        & ul {
          & li {
            font-size: 17px;
            line-height: 30px;
            color: rgb(168, 152, 137);
          }
        }

        & button,
        a {
          text-decoration: none;
          border: none;
          width: fit-content;
          border-radius: 8px;
          // color: #9c6a37;
          color: white;
          -webkit-box-shadow: 0 0 0 0 transparent;
          box-shadow: 0 0 0 0 transparent;
          background-color: var(--primary-orange);
          // background-color: var(--light-orange);
          padding: 20px 60px;
          font-size: 14.3px;
          text-transform: uppercase;
          cursor: pointer;
          font-weight: 700;
          transition: var(--transition);
          border: 1px solid var(--primary-orange);

          &:hover {
            transition: var(--transition);
            background: white;
            color: var(--primary-orange);
            border: 1px solid var(--primary-orange);
          }
        }
      }

      & .left,
      & .right {
        @media (max-width: $breakpoint-tablet) {
          max-width: 100%;
        }
      }
    }

    & .statements {
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      padding: 120px 0;
      flex-direction: column;
      gap: 50px;

      &-center {
        // background: rgba(255, 255, 255, 0.85);
        background: rgba(183, 183, 183, 0.8);
        border-radius: 16px;
        padding: 90px 100px;
        display: flex;
        flex-direction: column;
        gap: 60px;
        align-items: center;
        justify-content: center;
        width: 60%;

        & .statement-item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 30px;

          & img {
            background: var(--primary-green);
            padding: 20px;
            border-radius: 50%;
            width: 100px;
            height: 100px;
          }

          & h2 {
            font-size: 45px;
            letter-spacing: 1.5px;
            font-weight: 600;
            // color: #121111;
            color: black;

            & span {
              color: var(--primary-green);
            }
          }

          & p {
            width: 500px;
            text-align: center;
            color: rgb(7, 7, 7);
            font-weight: 300;
            font-size: 20px;
            letter-spacing: 0.5px;
            line-height: 25px;
          }
        }

        & .hawaree-statement {
          & img {
            background: var(--primary-orange);
          }

          & h2 {
            & span {
              color: var(--primary-orange);
            }
          }
        }
      }

      & .bottom {
        display: flex;
        gap: 50px;
        max-width: 60%;

        & .statement-item {
          background: rgba(183, 183, 183, 0.8);
          border-radius: 16px;
          padding: 50px 40px;
          // width: 100%;
          width: 410px;
          display: flex;
          flex-direction: column;
          gap: 30px;

          & img {
            background: var(--primary-green);
            padding: 20px;
            border-radius: 50%;
            width: 100px;
            height: 100px;
          }

          & h2 {
            font-size: 45px;
            letter-spacing: 1.5px;
            font-weight: 600;
            // color: #121111;
            color: black;

            & span {
              color: var(--primary-green);
            }
          }

          & p {
            color: rgb(7, 7, 7);
            font-weight: 300;
            font-size: 20px;
            letter-spacing: 0.5px;
            line-height: 25px;
          }

          & .spirits {
            display: flex;
            flex-direction: column;
            gap: 5px;

            & p {
              & span {
                width: 20px;
                display: inline-flex;
              }
            }
          }
        }

        & .hawaree-statement {
          & img {
            background: var(--primary-orange);
          }

          & h2 {
            & span {
              color: var(--primary-orange);
            }
          }
        }
      }
    }

    & .box {
      background: rgb(183, 183, 183);
      padding: 50px;
      border-radius: 16px;
      max-width: var(--max-width);
      margin: 0px auto 60px;
      width: 75%;

      &-center {
        display: flex;
        flex-direction: column;
        gap: 50px;

        &-item {
          display: flex;
          gap: 80px;

          & .image {
            width: 150px;
            height: 100px;

            & img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }

          & .right {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 60%;

            & .heading {
              border-bottom: 1px solid grey;
              // padding: 15px 0;

              & h2 {
                font-weight: 600;
                padding: 15px 0;
                position: relative;
                // color: white;
                font-size: 30px;

                &::after {
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  height: 3px;
                  width: 80px;
                  background: var(--primary-green);
                  border-radius: 3px;
                }
              }
            }
            & .hawaree-heading {
              & h2 {
                &::after {
                  background: var(--primary-orange);
                }
              }
            }
            & p {
              font-weight: 300;
              letter-spacing: 0.5px;
              color: rgb(68, 63, 63);
              line-height: 26px;
            }
          }
        }

        & .division {
          height: 10px;
          width: 100%;
          background-color: white;
          border-radius: 10px;
        }
      }
    }

    & .values {
      // border: 2px solid red;
      max-width: var(--max-width);
      margin: 0 auto;
      margin-top: 50px;
      display: flex;
      gap: 30px;
      justify-content: center;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        gap: 20px;
      }

      & .value-item {
        // border: 2px solid blue;
        padding: 40px 30px;
        width: 30%;
        max-width: 324px;
        background: white;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 6px;
        // border: none;
        -webkit-box-shadow: 0 5px 10px 7px rgba(136, 140, 133, 0.05);
        box-shadow: 0 5px 10px 7px rgba(136, 140, 133, 0.05);

        @media (max-width: $breakpoint-tablet) {
          width: 100%;
        }

        @media (max-width: $breakpoint-small) {
          padding: 30px 25px;
        }

        & img {
          height: 80px;
          width: 80px;
          background: #006600;
          border-radius: 50%;
          padding: 15px;
        }

        & h2 {
          color: #666666;
          font-size: 28px;
          text-transform: capitalize;
        }

        & p {
          font-size: 15px;
          line-height: 20px;
          text-align: left;
          letter-spacing: 1px;
        }

        & .items {
          & p {
            word-spacing: 5px;
            font-size: 15px;
          }
        }
      }
    }
  }

  & .section-three {
    // border: 2px solid red;
    background: var(--light-green);
    padding: 80px 0;

    & .center {
      width: 90%;
      max-width: 1179px;
      // border: 2px solid blue;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      & .team {
        display: flex;
        justify-content: center;
        gap: 30px;
        // row-gap: 0;
        flex-wrap: wrap;
        // align-items: center;

        @media (max-width: $breakpoint-tablet) {
          flex-direction: column;
          gap: 20px;
        }

        & .team-item {
          background: white;
          padding: 50px 30px;
          border-radius: 8px;
          width: 45%;
          max-width: 600px;
          // border: 2px solid green;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;
          justify-content: centers;
          box-shadow: 0 5px 10px 7px rgba(136, 140, 133, 0.05);

          @media (max-width: $breakpoint-tablet) {
            width: 100%;
          }

          // @media (max-width: $breakpoint-small) {
          //   padding: 20px 15px;
          // }

          @media (max-width: $breakpoint-small) {
            padding: 30px 15px;
          }

          & img {
            height: 392px;
            object-fit: cover;
            width: 100%;
            border-radius: 15px;
          }

          & h2 {
            font-size: 28px;
          }

          & .line {
            // padding: 15px 0;
            height: 3px;
            width: 10%;
            background: #666666;
          }

          & h3 {
            color: #ff6600;
            font-size: 18px;
          }

          & p {
            text-align: center;
            height: fit-content;

            & span {
              color: #006600;
              cursor: pointer;
            }
          }
        }
      }

      & .desc {
        color: #ff6600;
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      & h1 {
        font-size: 50px;
        font-weight: 700;
        color: var(--primary-green);
        margin-bottom: 30px;
      }
    }

    & .details {
      position: fixed;
      z-index: 3;
      // background: rgba(173, 131, 45, 0.8);
      background: var(--light-green-200);
      // opacity: 0.8;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      // padding-bottom: 103px;
      align-items: center;

      & .center {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 60%;
        max-width: var(--max-width);
        background: #ffffff;
        border-radius: 10px;
        padding: 100px;
        align-items: center;
        opacity: 1;
        position: relative;
        // border: 2px solid red;
        max-height: 80%;
        // height: fit-content;
        margin-top: 80px;
        transition: var(--transition);

        @media (max-width: $breakpoint-large) {
          width: 90%;
          padding: 30px;
        }
        @media (max-height: 750px) {
          width: 90%;
          padding: 80px;
        }
        @media (max-height: 700px) {
          // width: 90%;
          height: 50%;
          padding: 50px;
          overflow: scroll;
        }
        @media (max-height: 650px) {
          overflow: scroll;
        }

        & h4 {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          // color: #ad832d;
          color: var(--primary-orange);

          @media (max-width: $breakpoint-large) {
            font-size: 20px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 17px;
          }
          @media (max-height: 700px) {
            font-size: 15px;
          }
        }

        & p {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          color: #000000;
          text-align: justify;

          @media (max-width: $breakpoint-large) {
            font-size: 12px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 11px;
          }
          @media (max-height: 700px) {
            font-size: 10px;
          }
        }

        & .close {
          position: absolute;
          top: 30px;
          right: 45px;
          cursor: pointer;

          @media (max-width: $breakpoint-tablet) {
            top: 20px;
            right: 20px;
          }
        }
      }
    }
  }
}
